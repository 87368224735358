.chatPageWrapper {
    // height: calc(100vh - 60px - 40px); // total height + topbar height + extra padding
	height: calc(100vh - 40px); // total height + extra padding
	padding-bottom: 0;
	overflow: hidden;
	// margin-top: calc(60px + 20px); // topbar height + extra space

    .chatPageWrapperInner {
        height: 100%;
    }
}


.chat-box-right .chat-body .chat-detail .media .media-body .chat-msg,
.chat-box-right .chat-body .chat-detail .media .media-body.reverse .chat-msg {
    p {
        border-radius: 18px;
        position: relative;
        margin-bottom: 16px;
        // border: 1px solid #000;
        background-color: #efefef;
    }

    .timestamp {
        display: flex;
        justify-content: flex-end;
        font-size: 10px;
        text-align: right;
        opacity: 0.5;
        color: #000;
        font-style: italic;
        position: absolute;
        right: 0px;
        bottom: -17px;
    }
}

.chat-box-right .chat-body .chat-detail .media .media-body .chat-msg {
    position: relative;

    .timestamp {
        right: unset;
        left: 8px;
        white-space: nowrap;
    }

    .status {
        margin-left: 8px;

        .read {
            color: #22b783;
        }
    }

    // Keep it just below th text
    .sender {
        display: block;
        font-size: 10px;
        width: 100%;
        text-align: right;
        opacity: 0.5;
    }
}

.media {
    &.sending-loader {
        opacity: 0.5;
    }

    .avatar-box {
        .thumb-sm {
            // border: 1px solid #000;
            width: 32px!important;
            height: 32px!important;
            position: relative;
            top: 3px;
        }
    }
    
    .roomInitials {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .initial {
            // background-color: red;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: bold;
            
            &.one {
                background-color: var(--clr-orange-lighter);
                color: var(--clr-orange);
            }
            &.two {
                background-color: var(--clr-yellow-lighter);
                color: var(--clr-yellow);
            }
        }
    }
    
    img {
        // border: 1px solid #000;
        position: relative;
        top: 3px;
        right: 2px;
    }

    .media-body {
        p {
            border: 0!important;
        }
        &.reverse {
            p {
                background-color: rgb(85, 193, 250, 0.6) !important;
            }
        }
    }



    &.red {
        .media-img {
            .avatar-box {
                span {
                    background-color: var(--clr-red-lighter)!important;
                    color: var(--clr-red)!important;
                }
            }
            .fullName {
                color: var(--clr-red);
            }
        }
        .media-body .chat-msg:first-child p {
            background-color: var(--clr-red-lighter)!important;
        }
    }
    &.green {
        .media-img {
            .avatar-box {
                span {
                    background-color: var(--clr-green-lighter)!important;
                    color: var(--clr-green)!important;
                }
            }
            .fullName {
                color: var(--clr-green);
            }
        }
        .media-body .chat-msg:first-child p {
            background-color: var(--clr-green-lighter)!important;
        }
    }
    &.brown {
        .media-img {
            .avatar-box {
                span {
                    background-color: var(--clr-brown-lighter)!important;
                    color: var(--clr-brown)!important;
                }
            }
            .fullName {
                color: var(--clr-brown);
            }
        }
        .media-body .chat-msg:first-child p {
            background-color: var(--clr-brown-lighter)!important;
        }
    }
    &.orange {
        .media-img {
            .avatar-box {
                span {
                    background-color: var(--clr-orange-lighter)!important;
                    color: var(--clr-orange)!important;
                }
            }
            .fullName {
                color: var(--clr-orange);
            }
        }
        .media-body .chat-msg:first-child p {
            background-color: var(--clr-orange-lighter)!important;
        }
    }
    &.yellow {
        .media-img {
            .avatar-box {
                span {
                    background-color: var(--clr-yellow-lighter)!important;
                    color: var(--clr-yellow)!important;
                }
            }
            .fullName {
                color: var(--clr-yellow);
            }
        }
        .media-body .chat-msg:first-child p {
            background-color: var(--clr-yellow-lighter)!important;
        }
    }
    &.pink {
        .media-img {
            .avatar-box {
                span {
                    background-color: var(--clr-pink-lighter)!important;
                    color: var(--clr-pink)!important;
                }
            }
            .fullName {
                color: var(--clr-pink);
            }
        }
        .media-body .chat-msg:first-child p {
            background-color: var(--clr-pink-lighter)!important;
        }
    }
    &.blue {
        .media-img {
            .avatar-box {
                span {
                    background-color: var(--clr-blue-lighter)!important;
                    color: var(--clr-blue2)!important;
                }
            }
            .fullName {
                color: var(--clr-blue2);
            }
        }
        .media-body .chat-msg:first-child p {
            background-color: var(--clr-blue-lighter)!important;
        }
    }
}

.chat-detail {
    .loaderWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .loader {
            width: 35px;
        }
    }
}

.thread-loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;

    .loader {
        width: 35px;
    }
}

.thread-popup-loader-wrapper {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
        width: 35px;
    }
}


.chat-box-left {
    overflow: hidden;
    // margin-bottom: 100px;

    .chat-list {
        .media {
            cursor: pointer;
            border: none!important;
            border-top: 1px solid #e8ebf3!important;
            margin: 0!important;
    
            .media-body {
                p {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }
            }
        }

    }

    .nav.nav-tabs .nav-link {
        span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #22b783;
            color: #fff;
            border-radius: 50%;
            font-size: 10px;
            width: 18px;
            height: 18px;
            position: relative;
            top: -2px;
            margin-left: 4px;
        }
    }
}



#newMessageModal .modal-dialog {
    max-width: 600px;
}


.left-sidebar .menu-body .nav-item .nav-link {
    &:hover {
        background-color: rgba(255, 255, 225, 0.1);
    }
}


.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #232323b5;
    z-index: 100;
}


.chat-detail {
    padding-right: 30px;
    padding-left: 30px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(160, 160, 160, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(169, 169, 169, 0.3);
    background-color: #7d7d7d;
}

.chat-footer {
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    transition: 0.2s;

    &.active {
        box-shadow: 0 0 50px rgba(0,0,0,0.1);
    }

    .typing-area {
        position: relative;
    }


    .actions-area {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;

        .selectTemplateToggle {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #efefef;
            border-radius: 100%;
            transition: 0.2s;
            cursor: pointer;

            &:hover {
                filter: brightness(0.8);
            }

            &:active {
                transform: scale(0.8);
            }

            svg {
                width: 24px;
            }
        }

        .chat-features {
            a {
                margin: 0;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #22b783;
                border-radius: 100%;
                transition: 0.2s;

                &:hover {
                    filter: brightness(0.8);
                }

                &:active {
                    transform: scale(0.8);
                }

                i {
                    color: #fff;
                }
            }
        }
    }



    .selectTemplateModal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
        opacity: 0;
        pointer-events: none;
        
        &.show {
            opacity: 1;
            pointer-events: all;
            
            .inner {
                margin-bottom: 0;
            }
        }
        
        .overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.3);
            // z-index: -1;
        }
        
        .inner {
            max-width: 800px;
            width: 100%;
            background-color: #fff;
            padding: 16px 32px;
            position: relative;
            z-index: 1;
            margin-bottom: 100px;
            transition: 0.2s;
            border-radius: 16px;

            .modalHeader {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;

                .modalTitle {
                    font-size: 24px;
                }

                .closeModal {
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                    transition: 0.2s;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(0,0,0,0.1);
                    }
                    
                    i {
                        font-size: 32px;
                    }
                }
            }

            .textTemplates {
                padding-right: 16px;
                max-height: 500px;
                height: 100%;
                overflow-x: hidden;
                overflow-y: scroll;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 16px;

                .textTemplate {
                    border: 1px solid #dfdfdf;
                    padding: 16px;
                    max-height: 250px;
                    overflow: hidden;
                    cursor: pointer;
                    user-select: none;
                    transition: 0.2s;
                    border-radius: 8px;

                    &:hover {
                        border-color: #959595;
                    }

                    &:active {
                        transform: scale(0.8);
                        background: rgba(0,0,0,0.1);
                    }

                    &.selected {
                        border-color: #22b783;
                        background: rgba(34, 183, 131, 0.1);
                    }
                }
            }
        }
    }
}


.chat-msg p {
    min-width: 110px;
}


.avatar-box {
    .bg-soft-primary {
        &:hover {
            background: rgba(85,126,248,.08)!important;
            color: #557ef8!important;
        }
    }
}





#chat-search {
    border: 1px solid #000;
}



.chat-body-left {
    .chat-list {
        .media {
            transition: 0.2s;

            &:hover {
                background-color: rgb(0, 123, 167, 0.2);
            }
        }
    }
}






.chat-box-right {
    .chat-body {
        padding: 0;
        .chat-detail {
            .media {
                flex-direction: column;

                .media-img {
                    margin-bottom: 2px;
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    .avatar-box  {
                        width: 16px!important;
                        height: 16px!important;
                        display: flex;
                        align-items: center;

                        span {
                            width: 100%!important;
                            height: 100%!important;
                            font-size: 10px;
                            top: 0;
                        }
                    }

                    .fullName {
                        white-space: nowrap;
                        font-size: 10px;
                        font-weight: bold;
                    }

                    &.reverse {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: flex-end;
                    }
                }

                .media-body {
                    margin: 0;
                    width: 100%;

                    &.reverse {
                        .chat-msg {
                            margin-right: 0;

                            &:first-child p {
                                padding-right: 16px;
                            }
                        }
                    }

                    &:not(.reverse) {
                        align-items: flex-end;
                    }

                    .chat-msg {
                        margin-left: 0;
                    
                        &:first-child p {
                            padding-left: 16px;
                        }
                    }
                }
            }
        }
    }
}