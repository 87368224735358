.modal-wrapper {
    .modal-dialog {
      max-width: 720px; // Increased width for better content fit
    }
  
    .modal-content {
    //   padding: 20px; // Adding more padding to the content
    }
  
    .modal-header {
      padding-bottom: 0;
      .modal-title {
        font-weight: bold;
        font-size: 1.25rem;
        text-align: left;
      }
      .btn-close {
        font-size: 1rem;
      }
    }
  
    .modal-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .col-lg-5 {
        text-align: center;
        img {
          max-width: 100%; // Ensure QR code is responsive
        }
      }
  
      .col-lg-7 {
        padding-left: 20px; // Adding space between image and text content
        text-align: left;
  
        h4 {
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: 15px;
        }
  
        p {
          font-size: 1rem;
          color: #6c757d; // Text color for description
          line-height: 1.6;
        }
      }
    }
  
    .modal-footer {
      justify-content: flex-end;
      padding-top: 10px;
      button {
        font-size: 0.9rem;
        padding: 8px 16px;
      }
    }
  }
  