:root {
	--clr-brand: #f3f4f6;
	--clr-blue: #007ba7;

	--clr-red: #f7322d;
	--clr-red-light: #ffadac;
	--clr-red-lighter: #ffd6d5;

	--clr-green: #6eb525;
	--clr-green-light: #b9ff74;
	--clr-green-lighter: #dfffbf;

	--clr-brown: #8B5A2B;
	--clr-brown-light: #C49A6C;
	--clr-brown-lighter: #EED6B6;

	--clr-orange: #F57C00;
	--clr-orange-light: #FFB74D;
	--clr-orange-lighter: #ffecd1;

	--clr-yellow: #eebb00;
	--clr-yellow-light: #FFEB3B;
	--clr-yellow-lighter: #FFF9C4;
	
	--clr-pink: #f458b0;
	--clr-pink-light: #ffb0dd;
	--clr-pink-lighter: #ffe3f3;
	
	--clr-blue2: #007ba7;
	--clr-blue-light: #8ad7f3;
	--clr-blue-lighter: #d6f4ff;
}


.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
	max-width: 1560px;
}


.left-sidebar .brand .logo {
	width: 100%;
	height: 80px;
	display: block;
	padding: 12px;

	.logo-sm {
		width: 100%;
		height: 100%;
		object-fit: contain;		
	}
}

body.dark-sidebar {
	height: 100vh;
}

body.dark-sidebar .left-sidebar .brand {
	background-color: transparent;
	border: 0;
}

body.dark-sidebar .left-sidebar {
	// background-image: linear-gradient(#0f172a 70%, #007ba7);
	background-color: var(--clr-brand);
	border-right: 1px solid #000;
	min-width: unset;
}


.navbar-custom {
	margin-left: 70px;
	background-color: var(--clr-brand);
	border-bottom: 1px solid #000;
}

.page-wrapper .page-content-tab {
	width: calc(100% - 70px);
	min-height: unset;
	margin-top: calc(20px); // extra space
	padding-bottom: 0;
}

.left-sidebar {
	.brand {
		.logo {
			width: 70px;
		}
	}
	.menu-body {
		padding: 8px;
	}
	.navbar-vertical {
		.navbar-nav {
			.nav-link {
				display: block;
				padding: 12px;
				color: #000;
				opacity: 0.5;
				display: flex;
				align-items: center;
				gap: 8px;
				transition: 0.2s;
				border-radius: 8px;
				margin-bottom: 4px;
				justify-content: center;

				&:hover {
					background: rgba(255,255,255,0.1);
					opacity: 1;
				}

				&.active {
					background-color: rgb(255,255,255);
					color: #000;
					font-weight: bold;
					opacity: 1;
				}

				span {
					display: none!important;
				}
			}

		}
	}

	.menuSidebarBottom {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		text-align: center;
		width: 100%;
		padding: 8px;

		.userProfile {
			padding: 12px;
			color: #000;
			opacity: 0.5;
			display: flex;
			align-items: center;
			gap: 8px;
			transition: 0.2s;
			border-radius: 8px;
			margin-bottom: 4px;
			justify-content: center;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:hover {
				background-color: #fff;
				opacity: 1;
			}
		}

		.profileDropdown {
			position: absolute;
			bottom: 8px;
			left: calc(70px + 8px); // leftbar width + space
			background-color: #fff;
			padding: 8px;
			box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.2);
			border-radius: 8px;
			display: grid;
			gap: 4px;
			min-width: 150px;
		}

		.profileDropdownItem {
			padding: 8px 8px;
			color: #000;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;
			transition: 0.2s;
			border-radius: 8px;
			// margin-bottom: 4px;
			opacity: 0.7;
			cursor: pointer;

			span {
				font-weight: bold;
			}

			&:hover {
				// color: #fff;
				opacity: 1;
				background-color: #efefef;
			}

			&.logOut {
				&:hover {
					background-color: red;
					color: #fff;
				}
			}
		}
	}
}


.menu-icon {
	font-size: 18px;
}

.chat-box-left,
.chat-box-right {
	border: 1px solid #000;
	border-radius: 16px;
	overflow: hidden;
	height: 100%;
}
#all_chat::-webkit-scrollbar {
	// width: 0!important;
}
.chat-box-left {
	.nav-link {
		&.active {
			color: var(--clr-blue)!important;
		}
	}

	.chat-list {
		.media {
			align-items: center;

			.media-body {
				h6 {
					margin: 0;
					margin-bottom: 4px;
				}

				.time {
					white-space: nowrap;
					font-size: 9px;
				}
			}

			&.new-message {
				background-color: var(--clr-blue);

				.avatar-box .bg-soft-primary {
					background-color: #fff!important;
				}

				.media-body {
					h6 {
						color: #fff;
					}
					p {
						color: #fff;
					}

					.time {
						color: #fff;
					}
				}
			}
		}
	}
}
.chat-box-right {
	.chat-body {
		.chat-detail {
			// padding-bottom: 140px!important;
		}
	}
}


.chat-new-start {
	.btn {
		background-color: #fff;
		color: #000;
		font-weight: bold;
		border-radius: 100px;
		box-shadow: 8px 8px 0 rgba(0,0,0);
		position: relative;
		top: -8px;
		left: -8px;
		transition: 0.2s;
		border: 1px solid #000;
		padding: 8px;

		&:hover {
			top: 0;
			left: 0;
			box-shadow: 0 0 0 rgba(0,0,0);
			background-color: var(--clr-blue);
			color: #fff;
		}
	}
}






.modal-wrapper {
	.modal {
		.modal-content {
			border-radius: 8px;
			max-width: 600px;
			width: 100%!important;

			.modal-header {
				background-color: transparent;

				.modal-title {
					color: #000;
				}

				.btn-close {
					color: red;
				}
			}

			.modal-body {
				width: 100%;

				.modalInner {
					width: 100%;
				}
			}
		}
	}
}





@media (max-width: 991px) {
	.enlarge-menu {
		&.enlarge-menu-all {
			.left-sidebar {
				display: block;
				top: 0;
		
				.menu-body {
					.collapse:not(.show) {
						display: block;
					}
				}

				&:hover {
					width: 76px!important;
					min-width: unset;
					max-width: 76px;
					// overflow-x: hidden;
				}
			}
		}
	}

	.chatPageWrapper {
		height: auto;

		.chat-box-left {
			height: 300px;
		}

		#all_chat {
			max-height: calc(100vh - 330px - 40px - 16px - 50px - 90px - 30px);
			overflow-x: hidden;
		}

		.chat-box-right {
			margin-bottom: 32px;
			height: 80vh;

			.chat-header {
				padding: 4px 8px;
			}

			.chat-body {
				height: 300px;

				.chat-detail {
					padding: 0 16px!important;
					height: 380px!important;
				}
			}
		}

		.chat-window-center-inner-content {
			width: 200px;

			img {
				width: 100px;
			}
		}
	}
}