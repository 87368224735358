/* SCSS Updates */
.formWrapper {
    padding: 32px;
    background-color: #efefef;
    border: 1px solid #000;
    border-radius: 8px;
    margin-top: 16px;
    max-width: 800px;
  
    .form-group {
      margin-bottom: 16px;
  
      .form-control,
      .form-select {
        border: 1px solid #000;
      }
  
      label {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 4px;
      }
  
      .weekdays {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
  
        .form-check {
          display: flex;
          align-items: center;
  
          .form-check-input {
            margin-right: 8px;
          }
        }
      }
    }
  
    .buttons {
      text-align: right;
    }
  }
  