.manage-contacts-page {
    .pageTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .contactTableWrapper {
        .data-table-extensions-filter {
            text-align: right;
            margin: 16px 0;

            .filter-text {
                border: 1px solid #000;
                padding: 8px 16px;
                border-radius: 8px;
            }
        }
    }

    .modal {
        .form-label {
            color: #000;
        }
        .form-control {
            border: 1px solid #000;
            padding: 8px 16px;
            border-radius: 8px;
        }
    }
}