.chat-window {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f8f9fc; /* Light background for a modern look */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Soft shadow for a sleek look */
  }
  
  .chat-window-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: rgb(0, 123, 167, 0.1);
  }
  
  .chat-window-center {
    text-align: center;
  }
  
  .chat-window-center-inner-content {
    padding: 20px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Box shadow for an elevated look */
    width: 600px;
    padding: 50px 0;
    border: 1px solid black;
  }
  
  .chat-window-center-inner-content-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .chat-window-center-inner-content-inner img {
    max-width: 200px; /* Adjust the image size */
    object-fit: contain;
    margin-bottom: 20px;
    //   make it grayscale
    // filter: grayscale(100%);
    margin-bottom: 0;
  }
  
  .chat-window-center-inner-content-inner h4 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #343a40;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .chat-window-center-inner-content {
      max-width: 100%;
    }
  
    .chat-window-center-inner-content-inner h4 {
      font-size: 1rem;
    }
  }
  



.middleNotification {
  text-align: center;
  position: relative;
  opacity: 0.8;

  &::after {
    // content: '';
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  
  span {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
    font-style: italic;
    display: inline-block;
    max-width: 90%;

    .timestamp {
      // border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 100px;
      // padding: 0 8px;
      margin-left: 4px;
      color: rgba(0, 0, 0, 0.5);
    }

    &.success {
      color: green;
    }
    &.danger {
      color: brown;
    }
  }
}