.add-contact-modal {
  border-radius: 8px;
  // padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 500px; /* Increased width */
  text-align: center; /* Center align content */

  .modal-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-title {
      font-size: 16px;
      font-weight: 600;
      color: #333;
    }

    .btn-close {
      background: none;
      border: none;
      font-size: 18px;
      cursor: pointer;
      color: #999;
      transition: 0.2s ease-in-out;

      &:hover {
        color: #555;
      }
    }
  }

  .tab-container {
    display: flex;
    // border-bottom: 1px solid #e0e0e0;
    margin-bottom: 15px;
    width: 100%;

    button {
      flex: 1;
      border: none;
      padding: 12px;
      font-size: 14px;
      background: transparent;
      color: #333;
      cursor: pointer;
      transition: background 0.3s;
      font-weight: 500;
      border-radius: 8px;

      &:hover {
        background: rgba(0, 123, 255, 0.1);
      }

      &.active {
        background: var(--clr-blue);
        color: white;
        font-weight: bold;
      }
    }
  }

  .modal-body {
    // padding: 20px 0; /* More spacing for clarity */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contact-form,
    .file-upload {
      width: 100%; /* Increased width */
      max-width: 400px; /* Ensure good spacing */

      input {
        width: 100%;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 14px;
        text-align: left; /* Keep input text left-aligned */
        margin-bottom: 12px;
      }

      .upload-box {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f5f5;
        padding: 12px;
        border-radius: 5px;
        border: 1px solid #ddd;
        cursor: pointer;
        transition: background 0.2s;
        width: 100%; /* Full width for consistency */

        &:hover {
          background: #e0e0e0;
        }

        input {
          display: none;
        }

        span {
          font-size: 14px;
          color: #333;
        }
      }
    }

    .primary-btn {
      width: 100%; /* Full width buttons */
      background: #007bff;
      color: #ffffff;
      border: none;
      padding: 12px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      transition: background 0.2s ease-in-out;
      margin-top: 10px;

      &:hover {
        background: #0056b3;
      }
    }
  }

  .modal-footer {
    padding-top: 15px;
    text-align: center;
    border-top: 1px solid #e0e0e0;

    .close-btn {
      background: #f8f9fa;
      border: 1px solid #ddd;
      color: #333;
      font-size: 14px;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: background 0.2s ease-in-out;
      width: 100%;

      &:hover {
        background: #e0e0e0;
      }
    }
  }
  .result-table-container {
    max-height: 250px; // Limit height with scrolling
    overflow-y: auto;
    margin-top: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .result-table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
  
    th {
      background: #007bff;
      color: white;
      padding: 10px;
      text-align: left;
      font-size: 14px;
      font-weight: bold;
    }
  
    td {
      padding: 10px;
      border-bottom: 1px solid #e0e0e0;
      font-size: 14px;
    }
  
    tr.success-row {
      background: #e8f5e9;
      color: #2e7d32;
    }
  
    tr.error-row {
      background: #ffebee;
      color: #c62828;
    }
  
    tr:nth-child(even) {
      background: #f9f9f9;
    }
  }
  
}
