




#newCSVFileUploadModal {
    .modal-body {
        gap: 24px;
    }
    .modal-dialog {
        max-width: 90vw;
        width: auto;
        justify-content: center;

        .modal-content {
            max-width: auto;

            .modal-body {
                display: block;
            }
        }
    }

    .modalInner {
        &.modalLeft {
            width: 400px;
        }


        &.modalRight {
            .tableWrapper {
                max-height: 400px;
                overflow-y: scroll;
                overflow-x: hidden;

                .table {
                    text-align: center;

                    th {
                        font-weight: bold;
                        padding: 8px 0;
                        border-bottom: 1px solid #8997bd;
                    }

                    td {
                        padding: 8px 0;
                    }
                }
            }
        }
    }

    .sampleCSVWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        margin-bottom: 24px;
    }

    .csvUpload {
        border: 2px dashed #000;
        height: auto;
        padding: 32px 0;

        .custom-file-input {
            display: none;
        }

        .custom-file-label {
            background-color: transparent;
            background-color: #486bd3;
            color: #fff;
            border: none;
            text-align: center;
            padding: 10px 20px;
            height: auto;
            position: relative;
            margin: 0;
            text-align: center;
            display: block;
            margin: auto;
            max-width: 80%;
            border-radius: 100px;
            cursor: pointer;
            opacity: 0.8;

            &::after {
                content: none;
            }

            &:hover {
                filter: brightness(0.9);
                opacity: 1;
            }
        }
    }
}