.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
  
  .custom-modal-content {
    background-color: #ffffff;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    padding: 0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
  
    .modal-title {
      font-weight: bold;
      color: #333;
    }
  }
  
  .modal-body {
    display: flex;
    padding-top: 10px;
  
    .modal-image-section {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 10px;
  
      img {
        max-width: 100%;
      }
    }
  
    .modal-form-section {
      width: 60%;
  
      .modal-subtitle {
        font-size: 1rem;
        margin-bottom: 1rem;
        color: #555;
      }
  
      .form-group {
        margin-bottom: 1rem;
  
        label {
          font-weight: bold;
          color: #333;
        }
  
        .form-control, .form-select {
          border: 1px solid #ddd;
          border-radius: 4px;
          padding: 8px;
          background-color: #f9f9f9;
  
          &:focus {
            border-color: #007bff;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
          }
        }
      }
    }
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid #eaeaea;
  }
  